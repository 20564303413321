import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material';
import { DeepKeyOf } from '@/components/_dashboard/promotion/formik/type';
import LoadingComponent from '@/components/LoadingComponent';
import SearchNotFound from '@/components/SearchNotFound';
import _ from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import { filterListByKeys, sortList } from '@/utils/functions';
import { v4 } from 'uuid';
import { ICommonTableHead, ICommonTableHeadColumn, SortType } from './commonTableHead/commonTableHead';
import { ICommonTableToolbar, ISearchBox } from './commonTableToolbar/commonTableToolbar';

export type RowBodyProps<T, P> = P & { data: T };
interface ICommonTableProps<T, P = Record<string, unknown>> {
    columnList: ICommonTableHeadColumn<T>[];
    dataList: T[];
    isLoading: boolean;
    RowBodyComponent: React.ComponentType<P & { data: T }>;
    rowBodyProps?: Omit<P, 'data'>;
    tableRowKey: DeepKeyOf<T>;
    searchBox?: ISearchBox<T>;
    tableTitle?: string;
    sx?: CSSProperties;
    backgroundColor?: string;
    filterBoxes?: JSX.Element[];
}

export const CommonTable = <T, P = Record<string, unknown>>(props: ICommonTableProps<T, P>) => {
    const {
        columnList,
        dataList,
        isLoading,
        RowBodyComponent,
        rowBodyProps = {} as P,
        searchBox,
        tableRowKey,
        tableTitle,
        sx = {},
        backgroundColor,
        filterBoxes
    } = props;

    const [sortedDataList, setSortedDataList] = useState<T[]>(dataList);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);

    const getTableRowKeyValue = (data: T, property: DeepKeyOf<T>): string => {
        const key = _.get(data, property, v4());
        if (typeof key === 'string') return key;
        if (typeof key === 'number') return String(key);
        return v4();
    };

    const handleColumnTitleOnClick = (property: DeepKeyOf<T>, sortType: SortType): void => {
        if (!property) return;
        const sortedList = sortList(dataList, sortType, property);
        setSortedDataList(sortedList);
    };

    const handleSearchValueOnChange = (value: string): void => {
        if (!searchBox) return;
        if (searchBox.list.length === 0) return;
        if (value === '') {
            setSortedDataList(dataList);
            return;
        }
        const filteredData = filterListByKeys(dataList, searchBox.list, value);
        setSortedDataList(filteredData);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setSortedDataList(dataList);
    }, [dataList]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    margin: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                {tableTitle && (
                    <Typography sx={{ marginY: 'auto' }} variant="h4">
                        {tableTitle}
                    </Typography>
                )}
                {searchBox && (
                    <Box>
                        <ICommonTableToolbar placeholder={searchBox.placeholder} handleSearchValueOnChange={handleSearchValueOnChange} />
                    </Box>
                )}
                {filterBoxes && filterBoxes.map((filterBox, index) => <Box key={index}>{filterBox}</Box>)}
            </Box>
            <TableContainer sx={{ minWidth: 800, ...sx }}>
                <Table stickyHeader aria-label="sticky table">
                    <ICommonTableHead
                        columnList={columnList}
                        handleColumnTitleOnClick={handleColumnTitleOnClick}
                        backgroundColor={backgroundColor}
                    />
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <LoadingComponent />
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoading &&
                            sortedDataList
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((data) => (
                                    <RowBodyComponent key={getTableRowKeyValue(data, tableRowKey)} data={data} {...(rowBodyProps as P)} />
                                ))}
                    </TableBody>
                    {sortedDataList.length === 0 && !isLoading && (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <SearchNotFound isNoItem={true} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={sortedDataList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
};
