import { Outlet, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import { BookingRouteParams, MerchantOutletContext } from '@/@type/router/booking';
import LoadingComponent from '@/components/LoadingComponent';
import { useFetchMerchant } from '@/components/public/booking/components/useFetchMerchant';
import { useSnackbarHelper } from '@/components/useSnackbarHelper';
import { Box, Container, Divider, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import orderbuddyLogo from '../assets/png/orderbuddyLogo.png';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    zIndex: 10,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0)
    }
}));
// ----------------------------------------------------------------------

export default function MerchantOnlyLayout(): JSX.Element {
    const { merchantId } = useParams<BookingRouteParams>();
    const { showSnackbar } = useSnackbarHelper();
    const { merchant, bookingSettings, fetchMerchantLoading, fetchMerchantError } = useFetchMerchant(merchantId || '');
    const [context, setContext] = useState<MerchantOutletContext>({ merchantId: merchantId || '' });

    useEffect(() => {
        if (merchantId) {
            setContext((prev) => ({ ...prev, merchantId }));
        }
    }, [merchantId]);

    useEffect(() => {
        if (bookingSettings) {
            setContext((prev) => ({ ...prev, bookingSettings }));
        }
    }, [bookingSettings]);

    useEffect(() => {
        if (fetchMerchantError) {
            showSnackbar('Merchant not found', 'error');
        }
    }, [fetchMerchantError, showSnackbar]);

    return (
        <>
            <HeaderStyle>
                {fetchMerchantLoading && <LoadingComponent />}
                {!fetchMerchantLoading && !fetchMerchantError && merchant && (
                    <Container maxWidth="lg">
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{ maxWidth: 800, mx: 'auto' }}>
                            {/* Left section: Logo */}
                            <Box display="flex" alignItems="center">
                                <Avatar src={orderbuddyLogo} sx={{ width: 40, height: 40 }} />
                            </Box>

                            {/* Vertical divider */}
                            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                            {/* Right section: Merchant info */}
                            <Box>
                                <Typography variant="body1" fontWeight="bold">
                                    {merchant.name}
                                </Typography>
                                {merchant.altName && (
                                    <Typography variant="body2" color="text.secondary">
                                        {merchant.altName}
                                    </Typography>
                                )}
                                {merchant.phoneNumber && (
                                    <Typography variant="body2" color="text.secondary">
                                        {merchant.phoneNumber}
                                    </Typography>
                                )}
                                <Typography variant="body2" color="text.secondary">
                                    {merchant.address}
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                )}
            </HeaderStyle>

            <Box sx={{ marginTop: '160px' }}>
                <Outlet context={context} />
            </Box>
        </>
    );
}
