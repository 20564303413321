import { Method } from 'axios';

interface BookingAPIEndpoint {
    url: string;
    method: Method;
}

const BookingAPIBasePath = '/api';

export const bookingAPIPath = {
    merchant: {
        create: (): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants`,
            method: 'POST'
        }),
        update: (): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants`,
            method: 'PUT'
        }),
        query: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}`,
            method: 'GET'
        }),
        createTables: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}/tables`,
            method: 'POST'
        }),
        queryTables: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}/tables`,
            method: 'GET'
        }),
        updateTables: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}/tables`,
            method: 'PUT'
        }),
        deleteTables: (merchantId: string, tableId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}/tables/${tableId}`,
            method: 'DELETE'
        }),
        queryOpenTime: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}/openTime`,
            method: 'GET'
        }),
        updateOpenTime: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}/openTime`,
            method: 'PUT'
        })
    },
    booking: {
        create: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}`,
            method: 'POST'
        }),
        query: (merchantId: string, yearStr: string, bookingNumber: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/${yearStr}/${bookingNumber}`,
            method: 'GET'
        }),
        update: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}`,
            method: 'PUT'
        }),
        updateBookingSettings: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/settings`,
            method: 'PUT'
        }),
        queryBookingSettings: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/settings`,
            method: 'GET'
        }),
        queryBookingList: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}`,
            method: 'GET'
        }),
        queryBookingAvailableTime: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/availableBookingTime`,
            method: 'GET'
        })
    }
};
