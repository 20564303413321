// material
import { Avatar, Box, BoxProps } from '@mui/material';
import orderbuddyLogo from '../../assets/png/orderbuddyLogo.png';
// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps): JSX.Element {
    return (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            <Avatar src={orderbuddyLogo} sx={{ width: 40, height: 40 }} />
        </Box>
    );
}
