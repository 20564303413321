import { PromotionType } from '@/__generated__/types';

// ----------------------------------------------------------------------
function path(root: string, subLink: string) {
    return `${root}${subLink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PUBLIC = '/public';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components'
};

interface generatePromotionPathProps {
    id?: string;
    type?: PromotionType;
    isLoyalty?: boolean;
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    public: {
        saveBooking: (merchantId: string, yearStr?: string, bookingNumber?: string): string => {
            const queryParams = [];
            if (yearStr) {
                queryParams.push(`yearStr=${yearStr}`);
            }
            if (bookingNumber) {
                queryParams.push(`bookingNumber=${bookingNumber}`);
            }
            const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
            return path(ROOTS_PUBLIC, `/booking/${merchantId}/save${queryString}`);
        },

        queryBooking: (merchantId: string, yearStr: string, bookingNumber: string): string =>
            path(ROOTS_PUBLIC, `/booking/${merchantId}/query?yearStr=${yearStr}&bookingNumber=${bookingNumber}`)
    },
    general: {
        generalApp: path(ROOTS_DASHBOARD, '/generalApp')
        // ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
        // analytics: path(ROOTS_DASHBOARD, '/analytics'),
        // banking: path(ROOTS_DASHBOARD, '/banking')
    },
    mail: {
        root: path(ROOTS_DASHBOARD, '/mail'),
        all: path(ROOTS_DASHBOARD, '/mail/all')
    },
    chat: {
        root: path(ROOTS_DASHBOARD, '/chat'),
        new: path(ROOTS_DASHBOARD, '/chat/new'),
        conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
    },
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    kanban: path(ROOTS_DASHBOARD, '/kanban'),
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
        newUser: path(ROOTS_DASHBOARD, '/user/new'),
        editUser: (userId: string) => path(ROOTS_DASHBOARD, `/user/edit/${userId}`),
        account: path(ROOTS_DASHBOARD, '/user/account')
    },
    promotion: {
        root: path(ROOTS_DASHBOARD, '/promotion'),
        list: path(ROOTS_DASHBOARD, '/promotion/list'),
        history: path(ROOTS_DASHBOARD, '/promotion/history'),
        newOffer: path(ROOTS_DASHBOARD, `/promotion/offer`),
        newPromotion: path(ROOTS_DASHBOARD, '/promotion/new'),
        createPromotionPath: (props: generatePromotionPathProps) => {
            const { id, type, isLoyalty } = props;
            let subLink = '/promotion';
            if (isLoyalty) {
                subLink += '/loyalty';
            }
            if (type) {
                subLink += `/${type}`;
            }
            if (id) {
                subLink += `/${id}`;
            }
            return path(ROOTS_DASHBOARD, subLink);
        },
        editOffer: (id: string, type: string) => `${path(ROOTS_DASHBOARD, `/promotion/offer/${id}`)}?type=${type}`
    },
    loyalty: {
        root: path(ROOTS_DASHBOARD, '/loyalty'),
        member: path(ROOTS_DASHBOARD, '/loyalty/member')
    },
    giftCard: {
        root: path(ROOTS_DASHBOARD, '/giftCard'),
        balance: path(ROOTS_DASHBOARD, '/giftCard/balance'),
        accounts: path(ROOTS_DASHBOARD, '/giftCard/accounts'),
        history: path(ROOTS_DASHBOARD, '/giftCard/history'),
        editGiftCardAccount: (id: string) => path(ROOTS_DASHBOARD, `/giftCard/balance/${id}`),
        viewGiftCardAccountHistory: (id: string) => path(ROOTS_DASHBOARD, `/giftCard/history/${id}`)
    },
    FAQ: {
        root: path(ROOTS_DASHBOARD, '/FAQ'),
        sections: path(ROOTS_DASHBOARD, '/FAQ/sections'),
        preview: path(ROOTS_DASHBOARD, '/FAQ/preview')
    },
    appSections: {
        root: path(ROOTS_DASHBOARD, '/appSections'),
        list: path(ROOTS_DASHBOARD, '/appSections/list')
    },
    popularRestaurantCategories: {
        root: path(ROOTS_DASHBOARD, '/popular-restaurant-categories'),
        list: path(ROOTS_DASHBOARD, '/popular-restaurant-categories/list')
    },
    merchant: {
        root: path(ROOTS_DASHBOARD, '/merchant'),
        list: path(ROOTS_DASHBOARD, '/merchant/list'),
        uploadPhoto: path(ROOTS_DASHBOARD, '/merchant/upload-photo'),
        newMerchant: path(ROOTS_DASHBOARD, '/merchant/new'),
        updateMerchant: path(ROOTS_DASHBOARD, '/merchant/update-merchant'),
        merchantSurcharges: path(ROOTS_DASHBOARD, '/merchant/surcharges'),
        newMerchantSurcharge: path(ROOTS_DASHBOARD, '/merchant/new-surcharges'),
        updateOpeningHours: path(ROOTS_DASHBOARD, '/merchant/updateOpeningHours'),
        editById: (id: string) => path(ROOTS_DASHBOARD, `/merchant/${id}/edit`),
        merchantSettings: (id: string) => path(ROOTS_DASHBOARD, `/merchant/${id}/settings`),
        subscriptions: path(ROOTS_DASHBOARD, `/merchant/subscriptions`),
        productList: path(ROOTS_DASHBOARD, `/merchant/products`)
    },
    menu: {
        root: path(ROOTS_DASHBOARD, '/menu'),
        list: path(ROOTS_DASHBOARD, '/menu/list'),
        newMenu: path(ROOTS_DASHBOARD, '/menu/new'),
        editById: (id: string) => path(ROOTS_DASHBOARD, `/menu/${id}/edit`),
        menuCategoryByMenuId: (id: string) => path(ROOTS_DASHBOARD, `/menu/${id}/menuCategory`),
        menuGroup: path(ROOTS_DASHBOARD, '/menu/menuGroup'),
        createMenuGroup: path(ROOTS_DASHBOARD, '/menu/createMenuGroup'),
        cookingCategoryByMenuId: (id: string) => path(ROOTS_DASHBOARD, `/menu/${id}/cookingCategory`)
    },
    order: {
        root: path(ROOTS_DASHBOARD, '/order'),
        list: path(ROOTS_DASHBOARD, '/order/list'),
        detail: (orderNumber: string) => path(ROOTS_DASHBOARD, `/order/${orderNumber}/detail`)
    },
    bookingSessions: {
        root: path(ROOTS_DASHBOARD, '/sessions-booking'),
        list: path(ROOTS_DASHBOARD, '/sessions-booking/list'),
        newBooking: path(ROOTS_DASHBOARD, '/sessions-booking/new'),
        editById: (id: string) => path(ROOTS_DASHBOARD, `/sessions-booking/${id}/edit`)
    },
    booking: {
        root: path(ROOTS_DASHBOARD, '/booking'),
        settings: path(ROOTS_DASHBOARD, '/booking/settings'),
        list: (timestamp?: number): string => {
            const basePath = path(ROOTS_DASHBOARD, '/booking/list');
            return timestamp ? `${basePath}?timestamp=${timestamp}` : basePath;
        },
        calendar: path(ROOTS_DASHBOARD, '/booking/calendar'),
        tables: path(ROOTS_DASHBOARD, '/booking/tables'),
        link: path(ROOTS_DASHBOARD, '/booking/link'),
        detail: (id: string) => path(ROOTS_DASHBOARD, `/booking/${id}/detail`)
    },
    eCommerce: {
        root: path(ROOTS_DASHBOARD, '/e-commerce'),
        shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
        product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
        productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
        list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
        newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
        editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
        checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
        invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
    },
    blog: {
        root: path(ROOTS_DASHBOARD, '/blog'),
        posts: path(ROOTS_DASHBOARD, '/blog/posts'),
        post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
        postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
        newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
    },
    transactions: {
        root: path(ROOTS_DASHBOARD, '/transactions')
    },
    reports: {
        root: path(ROOTS_DASHBOARD, '/reports')
    },
    settings: {
        root: path(ROOTS_DASHBOARD, '/settings')
    },
    paymentSubscriptions: {
        success: (id: string) => `/subscriptions/${id}/payment/success`,
        error: (id: string) => `/subscriptions/${id}/payment/error`
    }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
