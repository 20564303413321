import {
  BuyNGetNFreePromotionInput,
  LoyaltyPromotionInput,
  LuckyDrawPromotionInput,
  OrderType,
  PercentagePromotionInput,
  PromotionStatus,
  PromotionType,
  PromotionUseType,
  SpendPromotionInput
} from '@/__generated__/types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import {
  BuyNGetNFreePromotion,
  LoyaltyBenefit,
  LoyaltySettings,
  OfferVoucherSettings,
  PercentagePromotion,
  Promotion,
  PromotionFunctionType,
  SpendPromotion
} from '../../../@type/promotion';
import {
  createBuyNGetNFreePromoMutation,
  createLoyaltyPromoMutation,
  createLuckyDrawPromoMutation,
  createPercentagePromoMutation,
  createSpendPromoMutation,
  getBuyNGetNFreePromotionQuery,
  getLoyaltyPromotionQuery,
  getLuckyDrawPromotionQuery,
  getPercentagePromotionQuery,
  getSpendPromotionQuery,
  updateBuyNGetNFreePromoMutation,
  updateLoyaltyPromoMutation,
  updateLuckyDrawPromoMutation,
  updatePercentagePromoMutation,
  updateSpendPromoMutation
} from '../../../redux/slices/promotion';
import { dispatch } from '../../../redux/store';
import { convertTime } from '../../../utils/helperTime';
import { EffectiveDateTimeType, weekdayList } from './effectiveDateTime/type/type';
import { DeepKeyOf } from './formik/type';

// interface OfferVoucherPromotion {
//   isOffer?: boolean;
//   offerVoucherSettings?: any;
// }

export function getInitialBuyNGetNPromotionState(): BuyNGetNFreePromotion {
  return {
    id: '',
    name: '',
    description: '',
    percentage: 0,
    discountAmount: 0,
    isPercentage: true,
    numberOfDeals: 10,
    availableType: [OrderType.Dinein, OrderType.Pickup],
    minimumAmount: 0,
    effectiveDateTime: initEffectiveDateTime(),
    isPublished: true,
    canBeUsedWithOtherPromotions: false,
    buyN: 1,
    freeN: 1,
    includedPromotionTags: [],
    excludedPromotionTags: [],
    includedGetItemsPromoTags: [],
    applyOptionDiscount: true,
    status: PromotionStatus.Draft,
    merchantId: '',
    usedQuantity: 0,
    createdDate: '',
    isOffer: false,
    upToAmount: 0,
    useType: PromotionUseType.Promotion
  };
}

export const initOfferVoucherSettings = () => {
  const offerVoucherSettings: OfferVoucherSettings = {
    offerName: '',
    offerDescription: '',
    effectiveHoursAfterOfferActivated: 12,
    effectiveDateTime: initEffectiveDateTime(),
    offerExcludedItemTags: [],
    minimumAmount: 0,
    upToAmount: 0,
    voucherTerms: ''
  };
  return offerVoucherSettings;
};

export const initLoyaltySettings = () => {
  const loyaltySettings: LoyaltySettings = {
    pointsPerDollar: 0,
    maxPointsPerTransaction: 0,
    expiryDays: 0,
    loyaltyReviewDate: 0,
    loyaltyBenefits: initLoyaltyBenefits()
  };
  return loyaltySettings;
};

export const initLoyaltyBenefits = () => {
  const loyaltyBenefit = initLoyaltyBenefit();
  return [loyaltyBenefit];
};

export const initLoyaltyBenefit = () => {
  const loyaltyBenefit: LoyaltyBenefit = {
    id: v4(),
    name: '',
    description: '',
    requiredPoints: 0,
    benefitPromotionIds: []
  };
  return loyaltyBenefit;
};

export function getInitialSpendPromotionState(): SpendPromotion {
  return {
    id: '',
    name: '',
    description: '',
    percentage: 100,
    discountAmount: 0,
    isPercentage: true,
    includedPromotionTags: [],
    excludedPromotionTags: [],
    numberOfDeals: 10,
    availableType: [OrderType.Dinein, OrderType.Pickup],
    minimumAmount: 0,
    effectiveDateTime: initEffectiveDateTime(),
    isPublished: true,
    canBeUsedWithOtherPromotions: false,
    discountedItemQty: 1,
    applyOptionDiscount: true,
    status: PromotionStatus.Draft,
    merchantId: '',
    usedQuantity: 0,
    createdDate: '',
    isSignUpPromotion: false,
    upToAmount: 0,
    isOffer: false,
    useType: PromotionUseType.Promotion
  };
}

export function getInitialPercentagePromotionState(): PercentagePromotion {
  return {
    id: '',
    name: '',
    description: '',
    percentage: 0,
    discountAmount: 0,
    isPercentage: true,
    includedPromotionTags: [],
    numberOfDeals: 10,
    availableType: [OrderType.Dinein, OrderType.Pickup],
    minimumAmount: 0,
    effectiveDateTime: initEffectiveDateTime(),
    isPublished: true,
    canBeUsedWithOtherPromotions: false,
    applyOptionDiscount: true,
    status: PromotionStatus.Draft,
    merchantId: '',
    usedQuantity: 0,
    createdDate: '',
    isSignUpPromotion: false,
    upToAmount: 0,
    isOffer: false,
    useType: PromotionUseType.Promotion
  };
}

export function getInitialPromotionState(
  promoType: PromotionType | undefined,
  isOffer?: boolean,
  isLoyalty?: boolean,
  merchantId?: string | null
): Promotion {
  const result: Promotion = {
    id: '',
    name: '',
    description: '',
    percentage: '',
    discountAmount: '',
    discountedItemQty: 1,
    isPercentage: true,
    includedPromotionTags: [],
    numberOfDeals: '',
    availableType: [OrderType.Dinein, OrderType.Pickup],
    minimumAmount: '',
    effectiveDateTime: initEffectiveDateTime(),
    canBeUsedWithOtherPromotions: false,
    applyOptionDiscount: true,
    status: PromotionStatus.Draft,
    merchantId: merchantId ?? '',
    usedQuantity: 0,
    isSignUpPromotion: false,
    upToAmount: 0,
    isOffer: isOffer ?? false,
    useType: PromotionUseType.Promotion,
    type: promoType ?? PromotionType.Percentage,
    buyN: 0,
    freeN: 1,
    isHiddenInApp: false,
    isLoyalty: isLoyalty ?? false,
    loyaltyDetails: {
      expiryDays: 0,
      loyaltyReviewDate: 0
    },
    loyaltySettings: initLoyaltySettings(),
  };
  if (promoType === PromotionType.Loyalty || isLoyalty) {
    result.offerVoucherSettings = initOfferVoucherSettings()
    result.isLoyalty = true;
    result.isOffer = true;
  }
  if (promoType === PromotionType.BuynGetFree) {
    result.minimumAmount = '';
    result.buyN = '';
    result.freeN = '';
  }
  if (promoType === PromotionType.Spend) {
    result.minimumAmount = '';
    result.discountedItemQty = '';
    result.percentage = 100;
    result.isPercentage = true;
    result.discountAmount = 0;
  }
  if (promoType === PromotionType.LuckyDraw) {
    result.winnerCount = '';
    result.entryLimitPerUser = '';
    result.winCountLimitPerPerson = '';
    result.isEntryAutomatic = false;
    result.resultingPromotions = [];
  }
  return result;
}

// Spend $100 and get a voucher for your next visit
export const getOfferPromoName = (promo: Promotion): string => {
  // Spend $100 and get a voucher for your next visit
  let message = '';
  const minimumAmount = promo.minimumAmount;
  if (Number(minimumAmount) > 0) {
    message += `Spend $${minimumAmount} and `;
  }
  const buyN = promo.buyN;
  if (buyN && Number(buyN) > 0) {
    message += `Buy ${promo.buyN} items and `;
  }
  if (message === '') {
    message += 'Get ';
  } else {
    message += 'get ';
  }
  message += 'a voucher for your next visit';
  return message;
};

// spend: Get 1 free for every $50 spent
// amount/percentage: 50% off
// buyNgetN: Buy 1, get 1 free at 50% off
// buyNgetN: Spend $50, get 1 at 50% off
// buyNgetN: Buy 1, get infinite at 50% off
export const getVoucherPromoName = (promo: Promotion): string => {
  const { buyN, freeN, isPercentage, percentage, discountAmount, discountedItemQty, type } = promo;
  let minimumAmount = promo.minimumAmount;
  if (promo.isOffer) {
    minimumAmount = promo.offerVoucherSettings?.minimumAmount;
  }

  let message = '';
  if (type === PromotionType.Spend) {
    // Spend $50, get XX for free.
    message = `Get ${discountedItemQty} free for every $${minimumAmount} spent`;
  }
  if (type === PromotionType.BuynGetFree) {
    // Simplify the construction of the message parts
    const buyNPart = buyN != null && Number(buyN) > 0 ? `Buy ${buyN}, ` : '';
    const minimumAmountPart =
      minimumAmount != null && Number(minimumAmount) > 0 ? `Spend $${minimumAmount}` : '';
    // Simplify the construction of the freeNPart
    let freeNPart = '';
    if (freeN !== undefined) {
      const freeNText = freeN === 0 || freeN === 'Infinite' ? 'all' : freeN;
      const discountText = isPercentage ? `${percentage}% off` : `$${discountAmount} off`;
      freeNPart = `Get ${freeNText} at ${discountText} `;
    }
    // Combine all parts
    const promotionParts = [buyNPart, minimumAmountPart, freeNPart]
      .filter((part) => part)
      .join(' ');
    message = promotionParts ? promotionParts.trim() : 'No promotion applicable';
  }
  if (type === PromotionType.Percentage) {
    // 50% off
    if (isPercentage) {
      message = `${percentage}% off`;
    }
    if (!isPercentage) {
      message = `$${discountAmount} off`;
    }
  }
  return message;
};

export const deletePromotionVariables = <T extends { [key: string]: any }>(promotion: T) => {
  delete promotion.id;
  delete promotion.createdDate;
  delete promotion.merchantId;
  delete promotion.usedQuantity;
  delete promotion.status;
  return promotion;
};

export const removeTypenameKey = (obj: any): void => {
  if (obj === null || typeof obj !== 'object') {
    return;
  }
  if (Array.isArray(obj)) {
    obj.forEach((element) => removeTypenameKey(element));
    return;
  }

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (key === '__typename') {
      delete obj[key];
    } else {
      removeTypenameKey(value);
    }
  });
};

export const initEffectiveDateTime = (): EffectiveDateTimeType[] => {
  const result: EffectiveDateTimeType[] = [];

  // 使用 Luxon 的 DateTime 获取当前时间
  const now = DateTime.now();

  const tempDateTime: EffectiveDateTimeType = {
    startDate: now.toFormat('yyyy-MM-dd'),
    endDate: now.toFormat('yyyy-MM-dd'),
    hours: [
      {
        openStart: convertTime(now.startOf('day').toJSDate(), 'time'),
        openEnd: convertTime(now.endOf('day').toJSDate(), 'time'),
      },
    ],
    recurringWeekDays: weekdayList,
  };

  result.push(tempDateTime);
  return result;
};

export const updateOfferSetting = <T extends { [key: string]: any }>(promotion: T) => {
  if (!promotion.isOffer) {
    delete promotion.offerVoucherSettings;
    return promotion;
  }
  return promotion;
};

export const formatTimeFromMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

export const generateDateStrings = (dateTimes: EffectiveDateTimeType[]): string[] => {
  return dateTimes.map((dateTime) => {
    const startDate = DateTime.fromISO(dateTime.startDate).toFormat('dd/MM/yyyy');
    const endDate = DateTime.fromISO(dateTime.endDate).toFormat('dd/MM/yyyy');
    const timeStrings = dateTime.hours
      .map((hours) => {
        const start = formatTimeFromMinutes(hours.openStart);
        const end = formatTimeFromMinutes(hours.openEnd);
        return `${start} - ${end}`;
      })
      .join('  ');

    return `${startDate} - ${endDate}  ${timeStrings}`;
  });
};

export const updateEffectiveDateTime = (
  effectiveDateTime: EffectiveDateTimeType[]
): EffectiveDateTimeType[] => {
  const currentDate = DateTime.now().toFormat('yyyy-MM-dd');
  const endDate = DateTime.now().plus({ days: 30 }).toFormat('yyyy-MM-dd');
  const updatedEffectiveDateTime: EffectiveDateTimeType[] = effectiveDateTime.map((item) => ({
    ...item,
    startDate: currentDate,
    endDate: endDate
  }));
  // only keep the first item
  return [updatedEffectiveDateTime[0]];
};

const createPercentageInput = (
  promotion: Promotion,
  isPublished: boolean
): PercentagePromotionInput => {
  const promo: PercentagePromotionInput = {
    name: promotion.name,
    description: promotion.description,
    percentage: Number(promotion.percentage),
    discountAmount: Number(promotion.discountAmount),
    isPercentage: promotion.isPercentage,
    excludedPromotionTags: promotion.excludedPromotionTags,
    includedPromotionTags: promotion.includedPromotionTags,
    includedGetItemsPromoTags: promotion.includedGetItemsPromoTags,
    numberOfDeals: Number(promotion.numberOfDeals),
    availableType: promotion.availableType,
    minimumAmount: Number(promotion.minimumAmount),
    effectiveDateTime: promotion.effectiveDateTime,
    isPublished: isPublished,
    canBeUsedWithOtherPromotions: promotion.canBeUsedWithOtherPromotions ?? false,
    applyOptionDiscount: promotion.applyOptionDiscount,
    isSignUpPromotion: promotion.isSignUpPromotion,
    isOffer: promotion.isOffer,
    offerVoucherSettings: promotion.offerVoucherSettings,
    upToAmount: Number(promotion.upToAmount),
    useType: promotion.useType,
    isHiddenInApp: promotion.isHiddenInApp,
    isPaymentRequired: promotion.isPaymentRequired,
    isLoyalty: promotion.isLoyalty
  };
  if (promotion.isLoyalty) {
    promo.loyaltyDetails = promotion.loyaltyDetails;
    if (promo.offerVoucherSettings) {
      promo.offerVoucherSettings.offerName = promotion.name;
      promo.offerVoucherSettings.offerDescription = promotion.description;
      promo.offerVoucherSettings.effectiveDateTime = promotion.effectiveDateTime;
      promo.offerVoucherSettings.effectiveHoursAfterOfferActivated = 0;
    }
  }
  return promo;
};

const createSpendInput = (promotion: Promotion, isPublished: boolean) => {
  const promo: SpendPromotionInput = {
    name: promotion.name,
    description: promotion.description,
    percentage: 100,
    discountAmount: Number(promotion.discountAmount),
    isPercentage: true,
    excludedPromotionTags: promotion.excludedPromotionTags,
    includedPromotionTags: promotion.includedPromotionTags,
    includedGetItemsPromoTags: promotion.includedGetItemsPromoTags,
    numberOfDeals: Number(promotion.numberOfDeals),
    availableType: promotion.availableType,
    minimumAmount: Number(promotion.minimumAmount),
    effectiveDateTime: promotion.effectiveDateTime,
    isPublished: isPublished,
    canBeUsedWithOtherPromotions: promotion.canBeUsedWithOtherPromotions ?? false,
    discountedItemQty: getDiscountedItemQty(promotion),
    applyOptionDiscount: promotion.applyOptionDiscount,
    isSignUpPromotion: promotion.isSignUpPromotion,
    isOffer: promotion.isOffer,
    offerVoucherSettings: promotion.offerVoucherSettings,
    upToAmount: Number(promotion.upToAmount),
    useType: promotion.useType,
    isHiddenInApp: promotion.isHiddenInApp,
    isPaymentRequired: promotion.isPaymentRequired,
    isLoyalty: promotion.isLoyalty
  };
  if (promotion.isLoyalty) {
    promo.loyaltyDetails = promotion.loyaltyDetails;
    if (promo.offerVoucherSettings) {
      promo.offerVoucherSettings.offerName = promotion.name;
      promo.offerVoucherSettings.offerDescription = promotion.description;
      promo.offerVoucherSettings.effectiveDateTime = promotion.effectiveDateTime;
      promo.offerVoucherSettings.effectiveHoursAfterOfferActivated = 0;
    }
  }
  return promo;
};

const createBuyNFreeNInput = (promotion: Promotion, isPublished: boolean) => {
  const promo: BuyNGetNFreePromotionInput = {
    name: promotion.name,
    description: promotion.description,
    buyN: Number(promotion.buyN) || 0,
    freeN: getFreeN(promotion),
    includedGetItemsPromoTags: promotion.includedGetItemsPromoTags,
    includedPromotionTags: promotion.includedPromotionTags,
    excludedPromotionTags: promotion.excludedPromotionTags,
    numberOfDeals: Number(promotion.numberOfDeals),
    availableType: promotion.availableType,
    minimumAmount: Number(promotion.minimumAmount),
    percentage: Number(promotion.percentage),
    discountAmount: Number(promotion.discountAmount),
    isPercentage: promotion.isPercentage,
    effectiveDateTime: promotion.effectiveDateTime,
    isPublished: isPublished,
    canBeUsedWithOtherPromotions: promotion.canBeUsedWithOtherPromotions ?? false,
    applyOptionDiscount: promotion.applyOptionDiscount,
    isOffer: promotion.isOffer,
    offerVoucherSettings: promotion.offerVoucherSettings,
    upToAmount: Number(promotion.upToAmount),
    useType: promotion.useType,
    isSignUpPromotion: promotion.isSignUpPromotion,
    isHiddenInApp: promotion.isHiddenInApp,
    isPaymentRequired: promotion.isPaymentRequired,
    isLoyalty: promotion.isLoyalty
  };
  if (promotion.isLoyalty) {
    promo.loyaltyDetails = promotion.loyaltyDetails;
    if (promo.offerVoucherSettings) {
      promo.offerVoucherSettings.offerName = promotion.name;
      promo.offerVoucherSettings.offerDescription = promotion.description;
      promo.offerVoucherSettings.effectiveDateTime = promotion.effectiveDateTime;
      promo.offerVoucherSettings.effectiveHoursAfterOfferActivated = 0;
    }
  }
  return promo;
};

const createLuckyDrawInput = (promotion: Promotion, isPublished: boolean) => {
  const promo: LuckyDrawPromotionInput = {
    name: promotion.name,
    description: promotion.description,
    numberOfDeals: Number(promotion.numberOfDeals),
    availableType: promotion.availableType,
    minimumAmount: Number(promotion.minimumAmount),
    canBeUsedWithOtherPromotions: promotion.canBeUsedWithOtherPromotions,
    effectiveDateTime: promotion.effectiveDateTime,
    isPublished: isPublished,
    prizeDescription: promotion.prizeDescription,
    useType: promotion.useType,
    isSignUpPromotion: promotion.isSignUpPromotion,
    winnerCount: Number(promotion.winnerCount),
    entryLimitPerUser: Number(promotion.entryLimitPerUser),
    isEntryAutomatic: promotion.isEntryAutomatic,
    resultingPromotions: promotion.resultingPromotions ?? [],
    isHiddenInApp: promotion.isHiddenInApp,
    isPaymentRequired: promotion.isPaymentRequired,
    winCountLimitPerPerson: Number(promotion.winCountLimitPerPerson),
    isLoyalty: promotion.isLoyalty,
    loyaltyDetails: promotion.isLoyalty ? promotion.loyaltyDetails : undefined
  };
  return promo;
};

const createLoyaltyInput = (promotion: Promotion, isPublished: boolean) => {
  const promo: LoyaltyPromotionInput = {
    name: promotion.name,
    description: promotion.description,
    numberOfDeals: Number(promotion.numberOfDeals),
    merchantId: promotion.merchantId,
    useType: promotion.useType,
    includedPromotionTags: promotion.includedPromotionTags,
    excludedPromotionTags: promotion.excludedPromotionTags,
    canBeUsedWithOtherPromotions: promotion.canBeUsedWithOtherPromotions,
    isHiddenInApp: promotion.isHiddenInApp,
    isLoyalty: promotion.isLoyalty,
    isPublished: isPublished,
    loyaltySettings: promotion.loyaltySettings ?? null,
    effectiveDateTime: promotion.effectiveDateTime
  };
  return promo;
};

export const queryPromoFunc = async (promotionType: PromotionType, promotionId: string) => {
  switch (promotionType) {
    case PromotionType.Percentage:
      {
        const percentageResult = await dispatch(
          getPercentagePromotionQuery({ promotionId })
        ).unwrap();
        percentageResult.type = PromotionType.Percentage;
        return percentageResult;
      }
    case PromotionType.Spend:
      {
        const spendResult = await dispatch(getSpendPromotionQuery({ promotionId })).unwrap();
        spendResult.type = PromotionType.Spend;
        return spendResult;
      }
    case PromotionType.BuynGetFree:
      {
        const buyNGetNFreeResult = await dispatch(
          getBuyNGetNFreePromotionQuery({ promotionId })
        ).unwrap();
        buyNGetNFreeResult.type = PromotionType.BuynGetFree;
        return buyNGetNFreeResult;
      }
    case PromotionType.LuckyDraw:
      {
        const luckyDrawResult = await dispatch(getLuckyDrawPromotionQuery({ promotionId })).unwrap();
        luckyDrawResult.type = PromotionType.LuckyDraw;
        return luckyDrawResult;
      }
    case PromotionType.Loyalty:
      {
        const loyaltyResult = await dispatch(getLoyaltyPromotionQuery({ promotionId })).unwrap();
        loyaltyResult.type = PromotionType.Loyalty;
        return loyaltyResult;
      }
    default:
      throw new Error('Invalid promotion type');
  }
};

export const createPromoFunc = async (
  promotion: Promotion,
  type: PromotionType,
  shouldPublish: boolean
) => {
  switch (type) {
    case PromotionType.Percentage:
      {
        const percentageResult = await dispatch(
          createPercentagePromoMutation({
            promotion: createPercentageInput(promotion, shouldPublish)
          })
        ).unwrap();
        return percentageResult;
      }
    case PromotionType.Spend:
      {
        const spendResult = await dispatch(
          createSpendPromoMutation({ promotion: createSpendInput(promotion, shouldPublish) })
        ).unwrap();
        return spendResult;
      }
    case PromotionType.BuynGetFree:
      {
        const buyNGetNFreeResult = await dispatch(
          createBuyNGetNFreePromoMutation({
            promotion: createBuyNFreeNInput(promotion, shouldPublish)
          })
        ).unwrap();
        return buyNGetNFreeResult;
      }
    case PromotionType.LuckyDraw:
      {
        const luckyDrawResult = await dispatch(
          createLuckyDrawPromoMutation({ promotion: createLuckyDrawInput(promotion, shouldPublish) })
        ).unwrap();
        return luckyDrawResult;
      }
    case PromotionType.Loyalty:
      {
        const loyaltyResult = await dispatch(
          createLoyaltyPromoMutation({ promotion: createLoyaltyInput(promotion, shouldPublish) })
        ).unwrap();
        return loyaltyResult;
      }
    default:
      throw new Error('Invalid promotion type');
  }
};

export const updatePromoFunc = async (
  promotion: Promotion,
  type: PromotionType,
  isPublished: boolean
) => {
  switch (type) {
    case PromotionType.Percentage:
      {
        const percentageResult = await dispatch(
          updatePercentagePromoMutation({
            promotionId: promotion.id,
            promotion: createPercentageInput(promotion, isPublished)
          })
        ).unwrap();
        return percentageResult;
      }
    case PromotionType.Spend:
      {
        const spendResult = await dispatch(
          updateSpendPromoMutation({
            promotionId: promotion.id,
            promotion: createSpendInput(promotion, isPublished)
          })
        ).unwrap();
        return spendResult;
      }
    case PromotionType.BuynGetFree:
      {
        const buyNGetNFreeResult = await dispatch(
          updateBuyNGetNFreePromoMutation({
            promotionId: promotion.id,
            promotion: createBuyNFreeNInput(promotion, isPublished)
          })
        ).unwrap();
        return buyNGetNFreeResult;
      }
    case PromotionType.LuckyDraw:
      {
        const luckyDrawResult = await dispatch(
          updateLuckyDrawPromoMutation({
            promotionId: promotion.id,
            promotion: createLuckyDrawInput(promotion, isPublished)
          })
        ).unwrap();
        return luckyDrawResult;
      }
    case PromotionType.Loyalty:
      {
        const loyaltyResult = await dispatch(
          updateLoyaltyPromoMutation({
            promotionId: promotion.id,
            promotion: createLoyaltyInput(promotion, isPublished)
          })
        ).unwrap();
        return loyaltyResult;
      }
    default:
      throw new Error('Invalid promotion type');
  }
};

const getDiscountedItemQty = (promotion: Promotion) => {
  if (promotion.discountedItemQty === 'Infinite') {
    return 0;
  }
  if (promotion.discountedItemQty === undefined) {
    return 0;
  }
  if (typeof promotion.discountedItemQty === 'string') {
    if (!Number.isFinite(Number(promotion.discountedItemQty))) {
      throw new Error('Invalid number string');
    }
    if (Number(promotion.discountedItemQty) <= 0) {
      throw new Error('Get Free Item Qty must be greater than 0');
    }
    return Number(promotion.discountedItemQty);
  }
  if (typeof promotion.discountedItemQty === 'number') {
    if (promotion.discountedItemQty <= 0) {
      throw new Error('Get Free Item Qty must be greater than 0');
    }
    return promotion.discountedItemQty;
  }
  throw new Error('Invalid number string');
};
const getFreeN = (promotion: Promotion) => {
  if (promotion.freeN === 'Infinite') {
    return 0;
  }
  if (promotion.freeN === undefined) {
    return 0;
  }
  if (typeof promotion.freeN === 'string') {
    if (!Number.isFinite(Number(promotion.freeN))) {
      throw new Error('Invalid number string');
    }
    return Number(promotion.freeN);
  }
  if (typeof promotion.freeN === 'number') {
    return promotion.freeN;
  }
  throw new Error('Invalid number string');
};

const numberCheck = (
  title: string,
  value: number | string | 'Infinite' | undefined | null,
  isGreaterThanZero: boolean,
  isInteger: boolean
) => {
  const isString = typeof value === 'string';
  if (isString) {
    if (value === 'Infinite') {
      return;
    }
    value = Number(value);
  }
  // check value type is number
  if (typeof value !== 'number') {
    throw new Error(`${title} must be a number`);
  }
  // check is NaN
  if (isNaN(value)) {
    throw new Error(`${title} must be a number`);
  }
  if (value < 0) {
    throw new Error(`${title} must be greater than or equal to 0`);
  }
  // check is greater than 0
  if (isGreaterThanZero && value === 0) {
    throw new Error(`${title} must be greater than 0`);
  }
  if (isInteger && !Number.isInteger(value)) {
    throw new Error(`${title} must be an integer`);
  }
  const decimalPart = value.toString().split('.')[1];
  if (decimalPart && decimalPart.length > 2) {
    throw new Error(`${title} must have at most 2 decimal places`);
  }
};

const stringCheck = (title: string, value: string | undefined, isNotEmpty: boolean) => {
  if (typeof value !== 'string' || value === undefined) {
    throw new Error(`${title} is not valid`);
  }
  if (isNotEmpty && value.trim() === '') {
    throw new Error(`${title} must not be empty`);
  }
};

export const promoNameValidation = (promo: Promotion) => {
  stringCheck('Name', promo.name, true);
};
export const promoDescriptionValidation = (promo: Promotion) => {
  stringCheck('Description', promo.description, true);
};
export const promoDiscountValidation = (promo: Promotion) => {
  if (promo.isPercentage) {
    numberCheck('Percentage', promo.percentage, true, false);
    return;
  }
  if (!promo.isPercentage) {
    numberCheck('Discount amount', promo.discountAmount, true, false);
    return;
  }
};

export const promoMinSpendBuyNValidation = (promo: Promotion) => {
  // if buyN = 0 and minSpend = 0, return
  if (!promo.buyN && !promo.minimumAmount) {
    throw new Error('Buy how many or Spend amount must be greater than 0');
  }
  if (promo.buyN === 0 && promo.minimumAmount === 0) {
    throw new Error('Buy how many or Spend amount must be greater than 0');
  }
  // if buyN = 0 and minSpend !== 0, minSpend is required
  if (promo.buyN === 0 && promo.minimumAmount !== 0) {
    numberCheck('Spend amount', promo.minimumAmount, true, false);
    return;
  }
  // if buyN !== 0 and minSpend = 0, buyN is required
  if (promo.buyN !== 0 && promo.minimumAmount === 0) {
    numberCheck('Buy how many', promo.buyN, true, true);
    return;
  }
};

export const promoDiscountItemQtyValidation = (promo: Promotion) => {
  numberCheck('Get how many', promo.discountedItemQty, true, true);
};

export const promoFreeNValidation = (promo: Promotion) => {
  numberCheck('Get how many', promo.freeN, true, true);
};

export const promoPublishDealsValidation = (promo: Promotion) => {
  numberCheck('Deals', promo.numberOfDeals, true, true);
};

export const promoMinimumAmountValidation = (promo: Promotion) => {
  numberCheck('Minimum amount', promo.minimumAmount, false, false);
};

export const promoWinnerCountValidation = (promo: Promotion) => {
  numberCheck('Winner Count', promo.winnerCount, true, true);
};

export const promoEntryLimitPerUserValidation = (promo: Promotion) => {
  numberCheck('Entry Limit', promo.entryLimitPerUser, true, true);
};

export const promoWinCountLimitPerPersonValidation = (promo: Promotion) => {
  numberCheck('Win Count Limit', promo.winCountLimitPerPerson, true, true);
};

export const promoPointsPerDollarValidation = (promo: Promotion) => {
  const { loyaltySettings } = promo;
  if (!loyaltySettings) {
    throw new Error('Loyalty settings is required');
  }
  const pointsPerDollar = loyaltySettings.pointsPerDollar;
  numberCheck('Points per dollar', pointsPerDollar, true, false);
};

export const promoMaxPointsPerTransactionValidation = (promo: Promotion) => {
  const { loyaltySettings } = promo;
  if (!loyaltySettings) {
    throw new Error('Loyalty settings is required');
  }
  const maxPointsPerTransaction = loyaltySettings.maxPointsPerTransaction;
  numberCheck('Max points per transaction', maxPointsPerTransaction, false, true);
};

export const promoExpiryDaysValidation = (promo: Promotion) => {
  const { loyaltySettings } = promo;
  if (!loyaltySettings) {
    throw new Error('Loyalty settings is required');
  }
  const expiryDays = loyaltySettings.expiryDays;
  numberCheck('Expiry days', expiryDays, false, true);
};

export const promoResultingPromotionsValidation = (promo: Promotion) => {
  const { resultingPromotions } = promo;
  // if resultingPromotions is undefined, throw error
  // if length of resultingPromotions is 0, throw error
  // !resultingPromotions includes undefined, null, 0, false, NaN, and an empty string "".
  if (!resultingPromotions) {
    throw new Error('Resulting Promotions is required');
  }
  if (resultingPromotions.length === 0) {
    throw new Error('Resulting Promotions is required');
  }
};

export const promoUpToAmountValidation = (promo: Promotion) => {
  numberCheck('Limit total discount amount', promo.upToAmount, false, false);
};

export const promoNameCheck = (promo: Promotion) => {
  promoNameValidation(promo);
};

export const promoEffectiveHoursAfterOfferActivatedValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  numberCheck(
    'Effective hours after offer activated',
    promo.offerVoucherSettings?.effectiveHoursAfterOfferActivated,
    false,
    true
  );
};

export const checkEffectiveDateTimeListValid = (
  effectiveDateTimeList: EffectiveDateTimeType[]
): boolean => {
  if (effectiveDateTimeList.length === 0) {
    throw new Error('Effective date time is required');
  }
  // const copyList = sortDateTime([...effectiveDateTimeList]);
  for (let i = 0; i < effectiveDateTimeList.length; i++) {
    const { startDate, endDate, hours } = effectiveDateTimeList[i];
    // Check that startDate is less than or equal to endDate
    if (new Date(startDate) > new Date(endDate)) {
      throw new Error(
        // startDate must be before or equal to endDate
        `${startDate} must be before or equal to ${endDate}.`
      );
    }
    // Iterate over each HoursType
    for (let j = 0; j < hours.length; j++) {
      const { openStart, openEnd } = hours[j];
      // Check that openStart is less than or equal to openEnd
      if (openStart > openEnd) {
        throw new Error(
          `${formatTimeFromMinutes(openStart)}  must be before or equal to  ${formatTimeFromMinutes(
            openEnd
          )}.`
        );
      }
      // Check that the previous element's openEnd is less than the current element's openStart
      if (j > 0 && new Date(hours[j - 1].openEnd) >= new Date(openStart)) {
        // openEnd must be before openStart
        throw new Error(
          `${formatTimeFromMinutes(openEnd)} must be before ${formatTimeFromMinutes(openStart)}.`
        );
      }
    }
    // Check that the previous element's endDate is less than the next element's startDate
    if (i > 0 && new Date(effectiveDateTimeList[i - 1].endDate) >= new Date(startDate)) {
      throw new Error(
        // previous endDate must be before current startDate
        `${effectiveDateTimeList[i - 1].endDate} must be before ${startDate}.`
      );
    }
  }
  return true; // Return true if all checks are passed
};

export const checkOfferVoucherEffectiveDateTimeValid = (
  offerList?: EffectiveDateTimeType[],
  nextVisitVoucherList?: EffectiveDateTimeType[]
) => {
  const offerDate = offerList && offerList.length > 0 ? findLatestDateTime(offerList) : new Date(0);
  const nextVisitVoucherDate =
    nextVisitVoucherList && nextVisitVoucherList.length > 0
      ? findLatestDateTime(nextVisitVoucherList)
      : new Date(0);
  if (offerDate > nextVisitVoucherDate) {
    throw new Error('Offer is valid up to or beyond the next visit voucher date.');
  }
};

export const promoOfferVoucherSettingNameValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  stringCheck('Name', promo.offerVoucherSettings.offerName, true);
};

export const promoOfferVoucherSettingsUpToAmountValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  numberCheck('Limit total discount amount', promo.offerVoucherSettings.upToAmount, false, false);
};

export const promoOfferVoucherSettingsMinimumAmountValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  numberCheck('Minimum amount', promo.offerVoucherSettings.minimumAmount, false, false);
};

export const baseInfoCheck = (promo: Promotion) => {
  // name is required
  // description is optional
  // numberOfDeals is required and greater than 0
  promoNameValidation(promo);
  promoPublishDealsValidation(promo);
};

export const voucherDetailsCheck = (promo: Promotion) => {
  // ----------- percentage/amount check-----------
  // if is percentage, percentage,upToAmount is required
  // if is amount, discount amount is required
  if (promo.type === PromotionType.Percentage) {
    // check minSpend or buyN (at least one is required)
    promoDiscountValidation(promo); // percentage and discount amount
    promoUpToAmountValidation(promo);
  }
  // ----------- buyNFreeN check -----------
  // check minSpend or buyN (at least one is required)
  // check freeN
  if (promo.type === PromotionType.BuynGetFree) {
    // check minSpend or buyN (at least one is required)
    promoDiscountValidation(promo); // percentage and discount amount
    promoUpToAmountValidation(promo);
    promoMinSpendBuyNValidation(promo);
    promoFreeNValidation(promo);
  }
  // ----------- spend check -----------
  // check minSpend
  // check discountItemQty
  if (promo.type === PromotionType.Spend) {
    promoDiscountValidation(promo); // percentage and discount amount
    promoUpToAmountValidation(promo);
    promoMinimumAmountValidation(promo);
    promoDiscountItemQtyValidation(promo);
  }
  if (promo.type === PromotionType.LuckyDraw) {
    // minimumAmount
    // winnerCount
    // entryLimitPerUser
    // winCountLimitPerPerson
    // resultingPromotions
    promoMinimumAmountValidation(promo);
    promoDiscountItemQtyValidation(promo);
    promoWinnerCountValidation(promo);
    promoEntryLimitPerUserValidation(promo);
    promoWinCountLimitPerPersonValidation(promo);
    promoResultingPromotionsValidation(promo);
  }
  if (promo.type === PromotionType.Loyalty) {
    // pointsPerDollar
    // maxPointsPerTransaction
    // expiryDays
    promoPointsPerDollarValidation(promo);
    promoMaxPointsPerTransactionValidation(promo);
    promoExpiryDaysValidation(promo);
  }
};

export const voucherConditionCheck = (promo: Promotion) => {
  // effectiveDateTime check
  if (!promo.effectiveDateTime) {
    throw new Error('Effective date time is required');
  }
  checkEffectiveDateTimeListValid(promo.effectiveDateTime);
};

export const offerDetailsCheck = (promo: Promotion) => {
  if (promo.type === PromotionType.BuynGetFree) {
    // check minSpend or buyN (at least one is required)
    promoMinSpendBuyNValidation(promo);
  }
};

export const offerConditionCheck = (promo: Promotion) => {
  // minimumAmount
  // offerVoucherSetting.effectiveHoursAfterOfferActivated
  promoMinimumAmountValidation(promo);
  promoEffectiveHoursAfterOfferActivatedValidation(promo);
  if (!promo?.effectiveDateTime) {
    throw new Error('Effective date time is required');
  }
  checkEffectiveDateTimeListValid(promo.effectiveDateTime);
};

export const offerVoucherBaseInfoCheck = (promo: Promotion) => {
  promoOfferVoucherSettingNameValidation(promo);
};

export const offerVoucherDetailsCheck = (promo: Promotion) => {
  // discountedItemQty
  if (promo.type === PromotionType.Spend) {
    promoMinimumAmountValidation(promo);
  }
  if (promo.type === PromotionType.BuynGetFree) {
    promoFreeNValidation(promo);
  }
  promoDiscountValidation(promo); // percentage and discount amount
  promoUpToAmountValidation(promo);
};
export const offerVoucherConditionCheck = (promo: Promotion) => {
  // promo.offerVoucherSettings?.upToAmount check
  // promo.offerVoucherSettings?.minimumAmount check
  promoOfferVoucherSettingsUpToAmountValidation(promo);
  promoOfferVoucherSettingsMinimumAmountValidation(promo);
  if (!promo.offerVoucherSettings?.effectiveDateTime) {
    throw new Error('Effective date time is required');
  }
  checkEffectiveDateTimeListValid(promo.offerVoucherSettings?.effectiveDateTime);
  checkOfferVoucherEffectiveDateTimeValid(
    promo.effectiveDateTime,
    promo.offerVoucherSettings?.effectiveDateTime
  );
};

export const getValidPromotions = (promotions: Promotion[]): Promotion[] => {
  const currentDate = new Date();
  const validPromotions = promotions.filter((promotion) => {
    return promotion.effectiveDateTime.some((dateTime) => {
      const endDate = new Date(dateTime.endDate);
      endDate.setDate(endDate.getDate() + 1);
      return currentDate <= endDate;
    });
  });
  return validPromotions;
};

export const getPromoIds = (promoList: Promotion[]) => {
  return promoList.map((promo) => promo.id);
};

export const getPromoListByIds = (promotions: Promotion[], ids: string[]): Promotion[] => {
  return promotions.filter((promotion) => {
    return ids.includes(promotion.id || '');
  });
};

export function parseDateAndTime(date: string, minutes: number): Date {
  const parts = date.split('-');
  if (parts.length !== 3) {
    throw new Error('Invalid date format. Please use "YYYY-MM-DD".');
  }

  const [year, month, day] = parts.map((num) => parseInt(num));
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    throw new Error('Date contains non-numeric values.');
  }

  if (year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31) {
    throw new Error('Date is out of valid range.');
  }

  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (minutes < 0 || minutes >= 1440) {
    throw new Error('Minutes should be between 0 and 1439.');
  }
  const dateObj = new Date(year, month - 1, day, hours, mins);
  if (isNaN(dateObj.getTime())) {
    throw new Error('Invalid date computed from input values.');
  }
  return dateObj;
}

export function findLatestDateTime(dates?: EffectiveDateTimeType[]): Date {
  if (!dates || dates.length === 0) {
    return new Date(0);
  }
  let latestDate = new Date(0);
  dates.forEach((date) => {
    date.hours.forEach((hour) => {
      const currentDateTime = parseDateAndTime(date.endDate, hour.openEnd);
      if (currentDateTime > latestDate) {
        latestDate = currentDateTime;
      }
    });
  });
  return latestDate;
}

export function createPromoValidator(
  excludedTypes: PromotionType[]
): (promo: Promotion) => boolean {
  return (promo: Promotion): boolean => {
    if (excludedTypes.includes(promo.type)) {
      return false;
    }
    if (promo.isOffer) {
      return false;
    }
    return true;
  };
}

export function promoLoyaltyBenefitsLengthCheck(loyaltySettings: LoyaltySettings): boolean {
  if (loyaltySettings.loyaltyBenefits.length === 0) {
    throw new Error('Loyalty benefits is required');
  }
  return true;
}
export function promoLoyaltyBenefitCheck(loyaltySettings: LoyaltySettings): boolean {
  if (!loyaltySettings) {
    throw new Error('Loyalty settings is required');
  }
  for (let i = 0; i < loyaltySettings.loyaltyBenefits.length; i++) {
    const loyaltyBenefit = loyaltySettings.loyaltyBenefits[i];
    if (!loyaltyBenefit.name) {
      throw new Error('Benefit name is required');
    }
    if (!loyaltyBenefit.requiredPoints) {
      throw new Error('Required points is greater than 0');
    }
    if (loyaltyBenefit.benefitPromotionIds.length === 0) {
      throw new Error('Benefit promotion is required');
    }
    numberCheck('Required points', loyaltyBenefit.requiredPoints, true, true);
  }
  return true;
}
export function hasOfferVoucherSettings(promo: Promotion): promo is Promotion & { offerVoucherSettings: NonNullable<Promotion['offerVoucherSettings']> } {
  return promo && promo.offerVoucherSettings !== undefined && promo.offerVoucherSettings !== null;
}

export function getVoucherDetailsValidationList(promo: Promotion): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];

  if (promo.type === PromotionType.Percentage) {
    validationList.push('minimumAmount');
    if (promo.isLoyalty) {
      validationList.push('loyaltyDetails.expiryDays');
    } else {
      validationList.push('percentage');
      validationList.push('discountAmount');
    }
  }

  if (promo.type === PromotionType.Spend) {
    validationList.push('minimumAmount');
    validationList.push('discountedItemQty');
  }

  if (promo.type === PromotionType.BuynGetFree) {
    validationList.push('minimumAmount');
    validationList.push('buyN');
    if (promo.isLoyalty) {
      validationList.push('loyaltyDetails.expiryDays');
    } else {
      validationList.push('freeN');
    }
  }

  if (promo.type === PromotionType.LuckyDraw) {
    validationList.push('minimumAmount');
    validationList.push('winnerCount');
    validationList.push('entryLimitPerUser');
    validationList.push('winCountLimitPerPerson');
  }

  if (promo.type === PromotionType.Loyalty) {
    validationList.push('loyaltySettings.pointsPerDollar');
    validationList.push('loyaltySettings.maxPointsPerTransaction');
    validationList.push('loyaltySettings.expiryDays');
  }
  return validationList;
}

export function getVoucherRewardsValidationList(promo: Promotion): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  if (promo.type === PromotionType.Percentage) {
    validationList.push('minimumAmount');
    validationList.push('percentage');
    validationList.push('discountAmount');
  }
  if (promo.type === PromotionType.Spend) {
    validationList.push('minimumAmount');
    validationList.push('discountedItemQty');
  }
  if (promo.type === PromotionType.BuynGetFree) {
    validationList.push('buyN');
    validationList.push('freeN');
    validationList.push('minimumAmount');
  }
  if (promo.type === PromotionType.LuckyDraw) {
    validationList.push('minimumAmount');
    validationList.push('winnerCount');
    validationList.push('entryLimitPerUser');
    validationList.push('winCountLimitPerPerson');
  }
  if (promo.type === PromotionType.Loyalty) {
    validationList.push('loyaltySettings.pointsPerDollar');
    validationList.push('loyaltySettings.maxPointsPerTransaction');
    validationList.push('loyaltySettings.expiryDays');
  }
  // Check if the promotion type is 'loyalty'
  if (promo.type === PromotionType.Loyalty) {
    if (promo.loyaltySettings) {
      validationList.push(`loyaltySettings.loyaltyBenefits`);
    }
    // Iterate through each loyalty benefit in loyaltySettings.loyaltyBenefits
    if (promo.loyaltySettings && Array.isArray(promo.loyaltySettings.loyaltyBenefits)) {
      for (let i = 0; i < promo.loyaltySettings.loyaltyBenefits.length; i++) {
        // Add validation paths for each loyalty benefit field
        validationList.push(`loyaltySettings.loyaltyBenefits[${i}].benefitPromotionIds`);
        validationList.push(`loyaltySettings.loyaltyBenefits[${i}].name`);
        validationList.push(`loyaltySettings.loyaltyBenefits[${i}].requiredPoints`);
      }
    }
  }

  return validationList;
}

export function getBaseInfoValidationList(funcType: PromotionFunctionType): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  validationList.push('name');
  if (funcType !== PromotionFunctionType.Loyalty) {
    validationList.push('numberOfDeals');
  }
  return validationList;
}

export function getVoucherConditionValidationList(): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  return validationList;
}

export function getOfferDetailsValidationList(promo: Promotion): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  // setFieldTouchedNameFunc('buyN');
  // setFieldTouchedNameFunc('minimumAmount');
  if (promo.buyN) {
    validationList.push('buyN');
  }
  if (promo.minimumAmount) {
    validationList.push('minimumAmount');
  }
  return validationList;
}

export function getOfferConditionValidationList(): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  validationList.push('offerVoucherSettings.effectiveHoursAfterOfferActivated');
  return validationList;
}
export function getOfferVoucherBaseInfoValidationList(): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  validationList.push('offerVoucherSettings.offerName');
  return validationList;
}

export function getOfferVoucherDetailsValidationList(promo: Promotion): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  if (promo.type === PromotionType.Spend) {
    validationList.push('minimumAmount');
  }
  if (promo.type === PromotionType.BuynGetFree) {
    validationList.push('freeN');
  }
  validationList.push('upToAmount');
  validationList.push('discountAmount');
  validationList.push('percentage');
  return validationList;
}

export function getOfferVoucherConditionValidationList(): DeepKeyOf<Promotion>[] {
  const validationList: DeepKeyOf<Promotion>[] = [];
  validationList.push('offerVoucherSettings.minimumAmount');
  validationList.push('offerVoucherSettings.upToAmount');
  return validationList;
}

export function getPromoFuncType(promo: Promotion): PromotionFunctionType {
  if ((promo.isLoyalty && promo.isOffer) || promo.type === PromotionType.Loyalty) {
    return PromotionFunctionType.Loyalty;
  }
  if (!promo.isLoyalty && promo.isOffer) {
    return PromotionFunctionType.Offer;
  }
  return PromotionFunctionType.Normal;
}

export function getPromoNamePlaceholder(promo: Promotion): string {
  const promoType = getPromoFuncType(promo);
  if (promoType === PromotionFunctionType.Loyalty) {
    return 'Please enter the name of the loyalty';
  }
  return 'Please enter the name of the voucher/offer/lucky draw';
}

export function getPromoDescriptionPlaceholder(promo: Promotion): string {
  const promoType = getPromoFuncType(promo);
  if (promoType === PromotionFunctionType.Loyalty) {
    return 'Please enter the description of the loyalty';
  }
  return 'Please enter the description of the voucher';
}

export function removePromoFromPromotion(promoId: string, promotion: Promotion): Promotion {
  const newPromo = _.cloneDeep(promotion);
  switch (promotion.type) {
    case PromotionType.LuckyDraw:
      return removePromoFromLuckyDrawReward(promoId, newPromo);
    case PromotionType.Loyalty:
      return removePromoFromLoyaltyReward(promoId, newPromo);
    default:
      throw new Error(`Unknown promotion type`);
  }
}

export function isEmptyReward(promotion: Promotion): boolean {
  switch (promotion.type) {
    case PromotionType.LuckyDraw:
      return isResultingPromotionsEmpty(promotion);
    case PromotionType.Loyalty:
      return isLoyaltyBenefitsEmpty(promotion);
    default:
      return false;
  }
}


export function getRelatedPromotions(promotions: Promotion[], filterFunction: (promotion: Promotion) => boolean): Promotion[] {
  return promotions.filter(filterFunction);
}

export function createPromotionFilterByPromoId(promoId: string) {
  return (promotion: Promotion): boolean =>
    isPromoInLuckyDrawReward(promoId, promotion) ||
    isPromoInLoyaltyReward(promoId, promotion);
}

export function isPromoInLuckyDrawReward(promoId: string, luckyDrawPromotion: Promotion): boolean {
  if (!luckyDrawPromotion.resultingPromotions) {
    return false;
  }
  return luckyDrawPromotion.resultingPromotions.includes(promoId);
}

export function isPromoInLoyaltyReward(promoId: string, loyaltyPromotion: Promotion): boolean {
  if (!loyaltyPromotion.loyaltySettings) {
    return false;
  }
  return loyaltyPromotion.loyaltySettings.loyaltyBenefits.some((benefit) =>
    benefit.benefitPromotionIds.includes(promoId)
  );
}

export function removePromoFromLuckyDrawReward(promoId: string, luckyDrawPromotion: Promotion): Promotion {
  if (!luckyDrawPromotion.resultingPromotions) {
    return luckyDrawPromotion;
  }
  luckyDrawPromotion.resultingPromotions = luckyDrawPromotion.resultingPromotions.filter((id) => id !== promoId);
  return luckyDrawPromotion;
}

export function removePromoFromLoyaltyReward(promoId: string, loyaltyPromotion: Promotion): Promotion {
  if (!loyaltyPromotion.loyaltySettings) {
    return loyaltyPromotion;
  }
  loyaltyPromotion.loyaltySettings.loyaltyBenefits.forEach((benefit) => {
    benefit.benefitPromotionIds = benefit.benefitPromotionIds.filter((id) => id !== promoId);
  });

  loyaltyPromotion.loyaltySettings.loyaltyBenefits = loyaltyPromotion.loyaltySettings.loyaltyBenefits.filter(
    (benefit) => benefit.benefitPromotionIds.length > 0
  );
  return loyaltyPromotion;
}

export function isResultingPromotionsEmpty(luckyDrawPromotion: Promotion): boolean {
  return !luckyDrawPromotion.resultingPromotions || luckyDrawPromotion.resultingPromotions.length === 0;
}

export function isLoyaltyBenefitsEmpty(loyaltyPromotion: Promotion): boolean {
  return (
    !loyaltyPromotion.loyaltySettings ||
    !loyaltyPromotion.loyaltySettings.loyaltyBenefits ||
    loyaltyPromotion.loyaltySettings.loyaltyBenefits.length === 0 ||
    loyaltyPromotion.loyaltySettings.loyaltyBenefits.every((benefit) => benefit.benefitPromotionIds.length === 0)
  );
}
