import { getErrorMessage } from '@/utils/stringHelper';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    close?: () => void | Promise<void>;
    action?: () => void | Promise<void>;
    message?: string | React.ReactNode;
    title?: string;
    children?: React.ReactNode;
    hiddenButton?: boolean;
    sx?: CSSProperties;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export default function ConfirmDialog({
    open,
    close,
    action,
    message,
    title,
    children,
    hiddenButton,
    fullWidth = false,
    maxWidth = 'sm',
    sx = {}
}: IProps): JSX.Element {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const handleClose = async (): Promise<void> => {
        if (close) await Promise.resolve(close());
    };

    const handleAction = async (): Promise<void> => {
        setIsLoading(true);
        try {
            if (action) await Promise.resolve(action());
            if (close) await Promise.resolve(close());
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            console.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    function renderMessage(message: React.ReactNode | string): React.ReactNode {
        if (typeof message === 'string') {
            return <DialogContentText id="alert-dialog-description">{message}</DialogContentText>;
        }
        return message;
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{
                    style: { pointerEvents: 'none' }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{ style: sx }}
            >
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent>
                    {children && children}
                    {renderMessage(message)}
                </DialogContent>
                {!hiddenButton && (
                    <DialogActions>
                        <Button onClick={handleClose}>{t('Cancel')}</Button>
                        <LoadingButton loading={isLoading} onClick={handleAction} autoFocus>
                            {t('Ok')}
                        </LoadingButton>
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
}
