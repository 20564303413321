import { CreateMerchantMutationVariables, Merchant, MerchantQueryVariables, UpdateMerchantMutationVariables } from '@/__generated__/types';
import { getErrorMessage } from '@/utils/stringHelper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { CREATE_MERCHANT, GET_ALL_MERCHANTS, GET_MERCHANT, UPDATE_MERCHANT } from '../../_apis_/queries/merchant';
import { removeTypenameKey } from '../../components/_dashboard/promotion/tools';
import { client } from '../../index';

// ----------------------------------------------------------------------

type MerchantState = {
    isLoading: boolean;
    error: boolean;
    merchantList: Merchant[];
};

const initialState: MerchantState = {
    isLoading: false,
    error: false,
    merchantList: []
};

const slice = createSlice({
    name: 'merchant',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state: any) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state: any, action: any) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET MERCHANT
        getMerchantsSuccess(state: any, action: PayloadAction<Merchant[]>) {
            state.isLoading = false;
            state.merchantList = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(queryAllMerchants.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(queryAllMerchants.fulfilled, (state, action) => {
            state.isLoading = false;
            state.merchantList = action.payload;
        });
        builder.addCase(queryAllMerchants.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const queryAllMerchants = createAsyncThunk('merchant/queryAllMerchants', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query<{ getAllMerchants: Merchant[] }>({
            query: GET_ALL_MERCHANTS,
            fetchPolicy: 'network-only'
        });
        if (!response.data) return rejectWithValue('No data returned');
        if (response.errors) return rejectWithValue(response.errors);
        // response.data.getAllMerchants is read-only object
        const copy = cloneDeep(response.data.getAllMerchants);
        removeTypenameKey(copy);
        return copy;
    } catch (error) {
        const errorMessage = getErrorMessage(error);
        return rejectWithValue(errorMessage);
    }
});

// export const UPDATE_MERCHANT = gql`
//   mutation updateMerchant($merchant: MerchantInput!) {
//     updateMerchant(merchant: $merchant)
//   }
// `;
export const updateMerchant = createAsyncThunk<boolean, UpdateMerchantMutationVariables, { rejectValue: string }>(
    'merchant/updateMerchant',
    async (input, { rejectWithValue }) => {
        try {
            const response = await client.mutate<{ updateMerchant: boolean }>({
                mutation: UPDATE_MERCHANT,
                variables: input
            });
            if (response.errors) return rejectWithValue(JSON.stringify(response.errors));
            if (!response.data) return rejectWithValue('No data returned');
            if (response.data) {
                return response.data.updateMerchant;
            }
            return rejectWithValue('No data returned');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            return rejectWithValue(errorMessage);
        }
    }
);

// export const CREATE_MERCHANT = gql`
//   mutation createMerchant($merchant: MerchantInput!) {
//     createMerchant(merchant: $merchant)
//   }
// `;
export const createMerchant = createAsyncThunk<boolean, CreateMerchantMutationVariables, { rejectValue: string }>(
    'merchant/createMerchant',
    async (input, { rejectWithValue }) => {
        try {
            const response = await client.mutate<{ createMerchant: boolean }>({
                mutation: CREATE_MERCHANT,
                variables: input
            });
            if (response.errors) return rejectWithValue(JSON.stringify(response.errors));
            if (!response.data) return rejectWithValue('No data returned');
            if (response.data) {
                return response.data.createMerchant;
            }
            return rejectWithValue('No data returned');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            return rejectWithValue(errorMessage);
        }
    }
);

// export const GET_MERCHANT = gql`
//   query merchant($merchantId: String!) {
//     getMerchant(merchantId: $merchantId) {
export const queryMerchant = createAsyncThunk<Merchant, MerchantQueryVariables>(
    'merchant/queryMerchant',
    async (input, { rejectWithValue }) => {
        try {
            const response = await client.query<{ getMerchant: Merchant }>({
                query: GET_MERCHANT,
                fetchPolicy: 'network-only',
                variables: input
            });
            if (!response.data) return rejectWithValue('No data returned');
            if (response.errors) return rejectWithValue(response.errors);
            // response.data.getMerchant is read-only object
            const copy = cloneDeep(response.data.getMerchant);
            removeTypenameKey(copy);
            return copy;
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            return rejectWithValue(errorMessage);
        }
    }
);

// Reducer
export default slice.reducer;
export const { getMerchantsSuccess } = slice.actions;
// Actions

// ----------------------------------------------------------------------
