import { Icon } from '@iconify/react';
import { IconButton, InputBase, Paper } from '@mui/material';
import { DeepKeyOf } from '@/components/_dashboard/promotion/formik/type';
import { useState } from 'react';

export interface ISearchBox<T> {
    list: DeepKeyOf<T>[];
    placeholder?: string;
}

interface IICommonTableToolbarProps {
    placeholder?: string;
    handleSearchValueOnChange: (value: string) => void;
}

export const ICommonTableToolbar = (props: IICommonTableToolbarProps): JSX.Element => {
    const { placeholder, handleSearchValueOnChange } = props;
    const [searchValue, setSearchValue] = useState<string>('');
    const [isFocused, setIsFocused] = useState(false);
    // handle search value change
    const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchValue(event.target.value);
        handleSearchValueOnChange(event.target.value);
    };
    // clear button click
    const handleClearButtonClick = (): void => {
        setSearchValue('');
        handleSearchValueOnChange('');
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            // prevent form submit
            event.preventDefault();
        }
    };

    return (
        <Paper
            component="form"
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
                border: '1px solid #ccc',
                borderRadius: '8px',
                boxShadow: isFocused ? 3 : 0,
                transition: 'box-shadow 0.3s ease'
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <Icon icon="material-symbols-light:search" width="24" height="24" />
            </IconButton>
            <InputBase
                sx={{
                    ml: 1,
                    flex: 1
                }}
                value={searchValue}
                onChange={handleSearchValueChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder || 'Search...'}
                inputProps={{ 'aria-label': placeholder || '' }}
            />
            {searchValue && (
                <IconButton onClick={handleClearButtonClick} type="button" sx={{ p: '10px' }} aria-label="search">
                    <Icon icon="ic:outline-clear" width="24" height="24" />
                </IconButton>
            )}
        </Paper>
    );
};
