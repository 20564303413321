import { Grid2 } from '@mui/material';
import React from 'react';

export interface tabContent {
  node: React.ReactElement | JSX.Element | tabContent | React.ReactElement[];
  mdSize?: number;
  sxSize?: number;
  isHidden?: boolean;
}

export const renderContent = (contents: tabContent[]): React.ReactNode => {
  return contents.map((content, index) => {
    if (content.isHidden) {
      return null;
    }
    let node: React.ReactNode;

    if (Array.isArray(content.node)) {
      node = content.node.map((element, i) => <React.Fragment key={i}>{element}</React.Fragment>);
    } else if ((content.node as tabContent).node) {
      node = renderContent([content.node as tabContent]);
    } else {
      node = content.node as React.ReactNode;
    }

    return (
      <Grid2 key={index} size={{ xs: content.sxSize || 12, md: content.mdSize || 6 }}>
        {node}
      </Grid2>
    );
  });
};
