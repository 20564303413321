import { Merchant } from '@/__generated__/types';
import { bookingMerchantQuery, bookingMerchantBookingSettingsQuery } from '@/_apis_/booking/axios';
import { useSnackbarHelper } from '@/components/useSnackbarHelper';
import { useEffect, useState } from 'react';
import { BookingSettings } from '@/@type/booking';

interface IUseFetchMerchantReturn {
  merchant: Merchant | null;
  bookingSettings: BookingSettings | null;
  fetchMerchantLoading: boolean;
  fetchMerchantError: string | null;
}

/**
 * Custom Hook for fetching merchant data.
 * @param merchantId - The ID of the merchant to fetch.
 * @returns Merchant data and loading/error states.
 */
export const useFetchMerchant = (merchantId: string): IUseFetchMerchantReturn => {
  const { showSnackbar } = useSnackbarHelper();
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  const [bookingSettings, setBookingSettings] = useState<BookingSettings | null>(null);
  const [fetchMerchantLoading, setFetchMerchantLoading] = useState<boolean>(true);
  const [fetchMerchantError, setFetchMerchantError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMerchant = async (): Promise<void> => {
      setFetchMerchantLoading(true);
      setFetchMerchantError(null);
      try {
        const merchantResp = await bookingMerchantQuery(merchantId);
        if (!merchantResp) {
          const errorMessage = 'Merchant not found';
          showSnackbar(errorMessage, 'error');
          setFetchMerchantError(errorMessage);
          console.error(errorMessage);
          return;
        }
        setMerchant(merchantResp);
        const bookingSettingsResp = await bookingMerchantBookingSettingsQuery(merchantId)
        if (!bookingSettingsResp) {
          const errorMessage = 'Booking Settings not found';
          showSnackbar(errorMessage, 'error');
          setFetchMerchantError(errorMessage);
          console.error(errorMessage);
          return;
        }
        setBookingSettings(bookingSettingsResp)
      } catch (err) {
        const errorMessage = 'Failed to fetch merchant data';
        showSnackbar(errorMessage, 'error');
        setFetchMerchantError(errorMessage);
        console.error(err);
      } finally {
        setFetchMerchantLoading(false);
      }
    };

    fetchMerchant();
  }, [merchantId, showSnackbar]);

  return { merchant, bookingSettings, fetchMerchantLoading, fetchMerchantError };
};
