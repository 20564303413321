import { Grid2 } from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect } from 'react';

import { PromotionType } from '@/__generated__/types';
import { PercentageSelector } from '@/components/_dashboard/promotion/components/percentageSelector';
import { PromoDiscountAmount } from '@/components/_dashboard/promotion/components/promoDiscountAmount';
import {
  getOfferVoucherDetailsValidationList,
  offerVoucherDetailsCheck
} from '@/components/_dashboard/promotion/tools';
import { CommonInput } from '@/components/_dashboard/promotion/formik/commonInput';
import { CommonCheckBox } from '@/components/_dashboard/promotion/formik/commonCheckBox';
import { renderContent, tabContent } from '../tabContent';
import { PromoTagsSelector } from '@/components/_dashboard/promotion/components/promoTagsSelector';
import { TagsIcons } from '@/components/_dashboard/promotion/components/tagsIcons';
import { PromoFreeN } from '@/components/_dashboard/promotion/components/promoFreeN';
import { PromoQtyRestricted } from '@/components/_dashboard/promotion/components/promoQtyRestricted';
import { IPromoProps } from '../../type';
import { getErrorMessage } from '@/utils/stringHelper';

export const OfferVoucherDetails: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const { values } = formik;
  const { excludedPromotionTags, discountedItemQty, type = PromotionType.BuynGetFree } = values;
  const [isExcludeTagsChecked, setIsExcludeTagsChecked] = React.useState(
    excludedPromotionTags && excludedPromotionTags.length > 0 ? true : false
  );

  const handleExcludedTagsCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('offerVoucherSettings.offerExcludedItemTags', []);
    }
    setIsExcludeTagsChecked(checked);
  };

  const canMove = useCallback(async () => {
    let message = '';
    let canMove = true;
    try {
      const list = getOfferVoucherDetailsValidationList(values);
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
      offerVoucherDetailsCheck(values);
    } catch (e) {
      message = getErrorMessage(e);
      canMove = false;
    }
    return {
      message: message,
      canMove: canMove
    };
  }, [values, setFieldTouchedNameFunc]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const baseContents: tabContent[] = [
    {
      mdSize: 12,
      node: (
        <CommonCheckBox
          formik={formik}
          value={isExcludeTagsChecked}
          handleOnChange={handleExcludedTagsCheckBoxOnChange}
          label="Exclude items from promotion"
        />
      )
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="offerVoucherSettings.offerExcludedItemTags"
          label="Inapplicable Items"
        />
      ),
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="offerVoucherSettings.offerExcludedItemTags" />,
      isHidden: !isExcludeTagsChecked
    }
  ];

  const spendContents: tabContent[] = [
    {
      mdSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'discountedItemQty'}
          disabled={discountedItemQty === 'Infinite'}
          type="text"
          label="Get how many?"
          placeholder="Buy 3 items"
        />
      )
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="offerVoucherSettings.offerExcludedItemTags"
          label="Applicable Items"
        />
      )
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="offerVoucherSettings.offerExcludedItemTags" />
    },
    ...baseContents,
    {
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="applyOptionDiscount"
          label="Apply discount to options"
        />
      )
    }
  ];

  const buyNFreeNContents: tabContent[] = [
    {
      mdSize: 12,
      node: <PromoQtyRestricted formik={formik} />
    },
    {
      node: <PromoFreeN formik={formik} label="Get how many? (next visit)" />
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="offerVoucherSettings.offerExcludedItemTags"
          label="Applicable Items"
        />
      )
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="offerVoucherSettings.offerExcludedItemTags" />
    },
    ...baseContents,
    {
      mdSize: 12,
      node: <PercentageSelector formik={formik} isOffer={false} label="Get Item Discount" />
    },
    {
      mdSize: 12,
      node: { node: <PromoDiscountAmount formik={formik} /> }
    },
    {
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="applyOptionDiscount"
          label="Apply discount to options"
        />
      )
    }
  ];

  const render = () => {
    switch (type) {
      case PromotionType.Spend:
        return renderContent(spendContents);
      case PromotionType.BuynGetFree:
        return renderContent(buyNFreeNContents);
      default:
        return null;
    }
  };

  return (
    <Grid2 sx={{ width: '100%' }} container spacing={1}>
      {render()}
    </Grid2>
  );
};
