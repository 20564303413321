import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormControl, IconButton, InputAdornment, TextField, TextFieldPropsSizeOverrides, Tooltip } from '@mui/material';
import { FormikProps, getIn } from 'formik';
import { OverridableStringUnion } from '@mui/types';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../styles';
import { DeepKeyOf } from './type';

interface commonInputProps<T> {
    formik: FormikProps<T>;
    fieldPath: DeepKeyOf<T>;
    type?: 'number' | 'text' | 'password';
    disabled?: boolean;
    handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    sx?: CSSProperties;
    label?: string;
    TooltipText?: string;
    placeholder?: string;
    endAdornment?: string | React.ReactNode;
    multiline?: boolean;
    boxSize?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
}

export const CommonInput = <T,>(props: commonInputProps<T>): JSX.Element => {
    const {
        formik,
        fieldPath = '',
        type = 'text',
        handleOnChange,
        disabled = false,
        sx = {},
        placeholder = '',
        multiline = false,
        label,
        TooltipText,
        endAdornment,
        boxSize
    } = props;
    const { touched, errors, getFieldProps } = formik;

    const { t } = useTranslation();
    const defaultFormControlStyles: CSSProperties = {
        width: '100%',
        ...sx
    };

    const getValue = (): string | number => {
        const value = getFieldProps(fieldPath).value;

        if (value === null || value === undefined) {
            return '';
        }

        if (typeof value === 'number') {
            const sanitizedValue = Number(String(value).replace(/^0+/, ''));
            return sanitizedValue;
        }
        if (typeof value === 'string') {
            return value;
        }
        return '';
    };

    const handleValueOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue = e.target.value;
        if (handleOnChange) {
            handleOnChange(e);
            return;
        }
        // if type is number, convert to number
        if (type === 'number') {
            // is NaN, return
            if (isNaN(Number(e.target.value))) {
                return;
            }
            // Remove leading zeros
            const sanitizedValue = inputValue.startsWith('0') && inputValue.length > 1 ? inputValue.replace(/^0+/, '') : inputValue;
            formik.setFieldValue(fieldPath, Number(sanitizedValue));
            e.target.value = sanitizedValue;
            return;
        }
        // if type is text, set value as is
        formik.setFieldValue(fieldPath, e.target.value);
    };

    return (
        <>
            <FormControl sx={defaultFormControlStyles}>
                {label && (
                    <Label>
                        {t(label)}
                        {TooltipText && (
                            <Tooltip title={t(TooltipText)}>
                                <IconButton>
                                    <HelpOutlineIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Label>
                )}
                <TextField
                    disabled={disabled}
                    multiline={multiline}
                    placeholder={t(placeholder)}
                    type={type}
                    inputMode={type === 'number' ? 'numeric' : 'text'}
                    name={fieldPath}
                    value={getValue()}
                    size={boxSize}
                    slotProps={{
                        input: {
                            endAdornment: endAdornment ? (
                                typeof endAdornment === 'string' ? (
                                    <InputAdornment position="end">{t(endAdornment)}</InputAdornment>
                                ) : (
                                    <InputAdornment position="end">{endAdornment}</InputAdornment>
                                )
                            ) : null
                        }
                    }}
                    onChange={handleValueOnChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(getIn(touched, fieldPath) && getIn(errors, fieldPath))}
                    helperText={getIn(touched, fieldPath) && getIn(errors, fieldPath)}
                />
            </FormControl>
        </>
    );
};
