import { gql } from '@apollo/client';

export const GET_ALL_MERCHANTS = gql`
    query getAllMerchants {
        getAllMerchants {
            merchantId
            name
            altName
            address
            phoneNumber
            abn
            suburb
            city
            latitude
            longitude
            logo
            logoImages
            createdAt
            merchantAdminEmail
            openTime {
                closeDates
                openHours {
                    openStart
                    openEnd
                    weekDays
                }
            }
            tags
            type
            restaurantType
            restaurantCategory
            settings {
                isStripePaymentEnabled
                areSoldOutItemsDisplayedInApp
                stripeAccountId
                maxPromotions
                appServices
            }
            timezone
            surchargeSettings {
                id
                name
                surchargeDescription
                weekdays
                isEnabled
                isPercentage
                amount
                publicHolidayDays
            }
            serviceMonthlyPrice
            orderbuddyMerchantId
            bookingMerchantId
            merchantStatus
        }
    }
`;

export const GET_MERCHANT = gql`
    query merchant($merchantId: String!) {
        getMerchant(merchantId: $merchantId) {
            merchantId
            name
            altName
            address
            phoneNumber
            abn
            suburb
            city
            latitude
            longitude
            logo
            logoImages
            createdAt
            merchantAdminEmail
            contactEmail
            openTime {
                closeDates
                openHours {
                    openStart
                    openEnd
                    weekDays
                }
            }
            tags
            type
            restaurantType
            restaurantCategory
            settings {
                isStripePaymentEnabled
                areSoldOutItemsDisplayedInApp
                stripeAccountId
                maxPromotions
                appServices
            }
            timezone
            surchargeSettings {
                id
                name
                surchargeDescription
                weekdays
                isEnabled
                isPercentage
                amount
                publicHolidayDays
            }
            serviceMonthlyPrice
            orderbuddyMerchantId
            bookingMerchantId
            merchantStatus
        }
    }
`;

export const GET_POS_MERCHANT = gql`
    query posMerchant($posRestaurantId: String!) {
        posMerchant(posRestaurantId: $posRestaurantId) {
            merchantId
            name
            altName
            address
            abn
            latitude
            longitude
            logo
            logoImages
            suburb
            city
            phoneNumber
            merchantAdminEmail
            createdAt
            restaurantType
            restaurantCategory
            tags
            timezone
            onlineStatus
            onlineOrderCount
            settings {
                isStripePaymentEnabled
                stripeAccountId
                maxPromotions
            }
            serviceMonthlyPrice
            orderbuddyMerchantId
            bookingMerchantId
            surchargeSettings {
                id
                name
                amount
                isEnabled
                isPercentage
                publicHolidayDays
                weekdays
                surchargeDescription
            }
        }
    }
`;

export const CREATE_MERCHANT = gql`
    mutation createMerchant($merchant: MerchantInput!) {
        createMerchant(merchant: $merchant)
    }
`;

export const ADD_USER_TO_MERCHANT = gql`
    mutation addUserToMerchant($role: UserRole!, $email: String!, $merchantId: String!) {
        addUserToMerchant(role: $role, email: $email, merchantId: $merchantId)
    }
`;

export const UPDATE_MERCHANT = gql`
    mutation updateMerchant($merchant: MerchantInput!) {
        updateMerchant(merchant: $merchant)
    }
`;

export const UPDATE_MERCHANT_OPEN_TIME = gql`
    mutation updateMerchantOpenTime($openTime: OpenTimeInput!, $merchantId: String!) {
        updateMerchantOpenTime(openTime: $openTime, merchantId: $merchantId)
    }
`;

export const SWITCH_MERCHANT = gql`
    mutation switchMerchant($merchantId: String!) {
        switchMerchant(merchantId: $merchantId)
    }
`;

export const UPDATE_MERCHANT_SETTINGS = gql`
    mutation updateMerchantSettings($merchantSettings: MerchantSetingsInput!, $merchantId: ID!) {
        updateMerchantSettings(merchantSettings: $merchantSettings, merchantId: $merchantId)
    }
`;

export const UPDATE_MERCHANT_HOLIDAY_SURCHARGE = gql`
    mutation updateMerchantSurcharges($surcharges: [SurchargeInput]!, $merchantId: ID!) {
        updateMerchantSurcharges(surcharges: $surcharges, merchantId: $merchantId)
    }
`;

export const GET_PRODUCTS = gql`
    query getProducts {
        getProducts {
            product {
                name
                description
            }
            unit_amount
            id
        }
    }
`;

export const DELETE_MERCHANT_LOGO = gql`
    mutation deleteMerchantLogo($imageName: String!) {
        deleteMerchantLogo(imageName: $imageName)
    }
`;

export const UPDATE_MERCHANT_LOGO = gql`
    mutation updateMerchantLogos($urlStringArray: [String]!) {
        updateMerchantLogos(urlStringArray: $urlStringArray)
    }
`;

export const GET_POS_MENUS = gql`
    query posMenus {
        posMenus {
            id
            name
            description
            isEnabled
            type
            status
            createdAt
            promotionTags
        }
    }
`;

export const GET_POS_MENU = gql`
    query posMenu($menuId: String!) {
        posMenu(menuId: $menuId) {
            id
            name
            description
            isEnabled
            type
            status
            createdAt
            promotionTags
        }
    }
`;

export const MERCHANT_AREAS = gql`
    query merchantAreas {
        merchantAreas {
            name
            description
            sort
            tables {
                minimumNumberOfGuests
                maximumNumberOfGuests
                tableNumber
            }
        }
    }
`;

// syncPOSMerchantData: Boolean!
export const SYNC_POS_MERCHANT_DATA = gql`
    mutation syncPOSMerchantData {
        syncPOSMerchantData
    }
`;
