import { DateTime } from 'luxon';
import { DEFAULT_TIME_ZONE, INVALID_DATE } from '../@type/global';
import { getLocalStorage } from './localStorage';

type DateInput = Date | string | number;
export const TIMEZONE = 'timezone';
const timezone = getLocalStorage(TIMEZONE);

// ----------------------------------------------------------------------

export function fDate(date: DateInput): string {
    return DateTime.fromJSDate(new Date(date)).toFormat('dd MMMM yyyy');
}

export function fDateTime(date: DateInput): string {
    return DateTime.fromJSDate(new Date(date)).toFormat('dd MMM yyyy p');
}

export function fTimestamp(date: DateInput): number {
    return DateTime.fromJSDate(new Date(date)).toMillis();
}

export function fDateTimeSuffix(date: DateInput): string {
    return DateTime.fromJSDate(new Date(date)).toFormat('dd/MM/yyyy hh:mm a');
}

export function fToNow(date: string | Date | number): string {
    const fromDate = typeof date === 'number' ? DateTime.fromMillis(date) : DateTime.fromJSDate(new Date(date));
    const now = DateTime.now();

    const diff = fromDate.toRelative({ base: now });

    // Ensure the return value is always a string
    return typeof diff === 'string' ? diff : 'just now';
}

export function fDateTimeSecond(date: number): string {
    if (typeof date !== 'number' || isNaN(date)) {
        throw new Error('Invalid date input for fDateTimeSecond: must be a valid number.');
    }
    return DateTime.fromMillis(date * 1000)
        .setZone(timezone || DEFAULT_TIME_ZONE)
        .toFormat('dd/MM/yyyy HH:mm:ss');
}

export function fDateTimeMillis(date: number, format?: string): string {
    let formatString = 'dd/MM/yyyy HH:mm:ss';
    if (format) {
        formatString = format;
    }
    if (typeof date !== 'number' || isNaN(date)) {
        throw new Error('Invalid date input for fDateTimeMillis: must be a valid number.');
    }
    return DateTime.fromMillis(date)
        .setZone(timezone || DEFAULT_TIME_ZONE)
        .toFormat(formatString);
}

// rm the parameter moment.Moment because there are no relevant calls to it.
export function fDateString(date: DateInput | null): string {
    const dateTime = parseDate(date);
    return dateTime instanceof DateTime ? dateTime.toFormat('yyyy-MM-dd') : dateTime;
}

export const fDateStringDD_MM_YYYY = (date: DateInput): string => {
    const dateTime =
        typeof date === 'string'
            ? DateTime.fromISO(date)
            : typeof date === 'number'
              ? DateTime.fromMillis(date)
              : DateTime.fromJSDate(date);

    if (!dateTime.isValid) return 'INVALID_DATE';

    return dateTime.toFormat('dd/MM/yyyy');
};

export function fDateStringDD_MM_YY(date: DateInput | null): string {
    const dateTime = parseDate(date);
    return dateTime instanceof DateTime ? dateTime.toFormat('dd-MM-yy') : dateTime;
}

export function fDateStringYY_MM_DD(date: DateInput | null): string {
    const dateTime = parseDate(date);
    return dateTime instanceof DateTime ? dateTime.toFormat('yy-MM-dd') : dateTime;
}

export function formatDate(dateStr: string): string {
    const date = DateTime.fromISO(dateStr);
    return date.toFormat('d LLL yyyy');
}

export function parseDateStringDD_MM_YY(dateStr: string): Date {
    const dt = DateTime.fromFormat(dateStr, 'dd-MM-yy');
    const timestamp = dt.toMillis(); //=> 1630435200000
    return new Date(timestamp);
}

function parseDate(date: DateInput | null): DateTime | string {
    if (date instanceof Date) {
        return DateTime.fromJSDate(date);
    } else if (typeof date === 'string') {
        const isoDate = DateTime.fromISO(date);
        if (isoDate.isValid) return isoDate;
        const customDate = DateTime.fromFormat(date, 'yyyy-MM-dd H:mm:ss');
        if (customDate.isValid) return customDate;
    } else if (typeof date === 'number') {
        return DateTime.fromMillis(date);
    }
    return INVALID_DATE;
}
